import VerticalTimeline from '@jebrewton/react-vertical-timeline-component/dist-modules/VerticalTimeline';
import VerticalTimelineElement from '@jebrewton/react-vertical-timeline-component/dist-modules/VerticalTimelineElement';
import '@jebrewton/react-vertical-timeline-component/style.min.css';
import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { get, map } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import { ParallaxSection } from '../modules/ts-comps/src';
// import bg1 from '../images/3-1920x1252.jpg';
import bg1 from '../images/4-1920x1440.jpg';
import './TimeLine.css';
import './tl.scss';

const TimeLine = ({ browser }) => {
  const belowBreakPoint = get(browser, 'lessThan.large', false);
  const getSizes = () => {
    if (belowBreakPoint) {
      return {
        height: '50%',
        width: '50%',
        left: 22,
        top: 22,
      };
    }
    return {
      height: '50%',
      width: '50%',
      left: 27,
      top: 27,
    };
  };

  const iconStyles = getSizes();

  const EndIcon = () => (
    <FontAwesomeIcon icon={faEllipsisH} style={iconStyles} />
  );

  const MidIcon = () => (
    <FontAwesomeIcon icon={faEllipsisV} style={iconStyles} />
  );

  const TimeLineData = [
    {
      date: 'Aug 2017 – Present',
      icon: (<EndIcon />),
      company: 'Paraclete Counseling Center',
      positions: ['Counselor'],
      locations: ['Suwanee, GA'],
      content: (
        <p />
      ),
    },
    {
      date: 'Nov 2013 – Aug 2017',
      icon: (<MidIcon />),
      company: 'Georgia Counseling Center',
      positions: ['Counselor'],
      locations: ['Woodstock, GA'],
      content: (
        <div>
          <p>
            I provided mental health counseling to adults,
            adolescents, couples, and families. I worked with
            clients who struggled with concerns such as anxiety,
            depression, bipolar disorder, substance abuse, etc.
          </p>
          <p>
            I also provided forensic counseling services for
            adolescent and adult sexual offenders who are
            struggling with sexual addiction, inappropriate
            arousal, etc.
          </p>
        </div>
      ),
    },
    {
      date: 'Nov 2013 – Dec 2013',
      icon: (<MidIcon />),
      company: 'Diversion Center',
      positions: ['Group Leader'],
      locations: ['Duluth, GA', 'Lawrenceville, GA', 'Marietta, GA'],
      content: (
        <div>
          <p>
            ASAM/drug and alcohol abuse groups and shoplifting groups.
          </p>
        </div>
      ),
    },
    {
      date: 'May 2012 – Apr 2013',
      icon: (<MidIcon />),
      company: 'Skyland Trail',
      positions: ['Admissions/Young Adult Intern'],
      locations: ['Greater Atlanta Area'],
      content: (
        <div>
          <p>
            I worked with the admission team to assess potential clients
            to determine if they were appropriate for any of the residential
            or PHP/IOP programs at Skyland Trail.
          </p>
          <p>
            I also worked with clients aged 18-mid 20s who were currently
            or had recently suffered from a psychotic episode.
            Most of these clients have a primary diagnosis of
            Schizophrenia, Schizoaffective, or Bipolar Disorder.
            I also led groups for the general population at Skyland
            Trail which include CBT Workshop and Codependent No More.
          </p>
        </div>
      ),
    },
    {
      date: 'May 2012 – Apr 2013',
      icon: (<MidIcon />),
      company: 'Hope Counseling Center',
      positions: ['Counselor in Training'],
      locations: ['Snellville, GA'],
      content: (
        <div>
          <p>
            I provided psychotherapy to individuals, couples, and families.
          </p>
        </div>
      ),
    },
  ];

  return (
    <ParallaxSection
      backgroundImage={bg1}
      blur={5}
      bgImageAlt="Counselor/therapist scheduling notebook image"
    >
      <VerticalTimeline animate={false}>

        {
          map(TimeLineData, (data, i) => (
            <VerticalTimelineElement
              key={i}
              className=""
              date={(<span className={belowBreakPoint ? 'text-dark' : 'text-light text-shadow'}>{data.date}</span>)}
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              icon={data.icon}
            >
              <h3 className="vertical-timeline-element-title">{data.company}</h3>
              {
                map(data.positions, (position, posIdx) => (
                  <h4 key={posIdx} className="vertical-timeline-element-subtitle my-3 font-italic">{position}</h4>
                ))
              }
              {
                map(data.locations, (location, posIdx) => (
                  <h4 key={posIdx} className="vertical-timeline-element-subtitle">{location}</h4>
                ))
              }
              {data.content}
            </VerticalTimelineElement>
          ))
        }
        <VerticalTimelineElement
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          icon={<EndIcon />}
        />
      </VerticalTimeline>
    </ParallaxSection>
  );
};

TimeLine.propTypes = {
  browser: PropTypes.object.isRequired,
};

export default compose(
  pure,
)(TimeLine);
