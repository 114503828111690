import PropTypes from 'prop-types';
import React from 'react';
import { GridSection } from '../modules/ts-comps/src';

const Footer = ({
  fullName,
  credentials,
}) => (
  <footer>
    <GridSection fluid>
      <footer className="page-footer bg-primary">
        <div className="footer-copyright text-center py-3 text-light">
            © 2020 Copyright - {fullName}, {credentials}
        </div>
        <div className="footer-copyright text-center py-3 text-light">
            3905 Johns Creek Ct #260, Suwanee, GA 30024 <br />
          <a href="http://paracletecounseling.com/">paracletecounseling.com</a>
        </div>
      </footer>
    </GridSection>
  </footer>
);

Footer.propTypes = {
  fullName: PropTypes.string.isRequired,
  credentials: PropTypes.string.isRequired,
};

export default Footer;
