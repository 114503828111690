import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { Container, Row, Col } from 'reactstrap';

const GridSection = ({ children, fluid, ...props }) => (
  <Container {...{ fluid }} {...props} className={fluid ? 'px-0' : ''}>
    <Row className={fluid ? 'mx-0' : ''}>
      <Col xs={12} className={fluid ? 'px-0' : ''}>
        {children}
      </Col>
    </Row>
  </Container>
);

GridSection.propTypes = {
  children: PropTypes.node.isRequired,
  fluid: PropTypes.bool,
};

GridSection.defaultProps = {
  fluid: false,
};

export default compose(
  pure,
)(GridSection);
