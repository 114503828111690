import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import {
  Card, CardBody, Row, Col, CardImg,
} from 'reactstrap';
import { connect } from 'react-redux';
import { withBreakpoints } from 'react-breakpoints';

import { map, get } from 'lodash';
import { faComments } from '@fortawesome/free-regular-svg-icons/faComments';
import { graphql } from 'gatsby';
import { window } from 'browser-monads';
import ScrollableAnchor from 'react-scrollable-anchor';
import { ParallaxSection, GridSection } from '../modules/ts-comps/src';
import bg1 from '../images/1-1920x1280.jpg';
import corrieImg from '../images/corrie-1-1014x676.jpg';
import mentalHealthImg from '../images/2-1920x1280.jpg';
import gottImg from '../images/hand-492x328.jpg';
import bookImg from '../images/book-492x328.jpg';
import ringImg from '../images/ring-492x328.jpg';
import anxImg from '../images/anx-3-492x328.jpg';
import Eli from '../components/Eli';
import ServicePoints from '../components/ServicePoints';
import TimeLine from '../components/TimeLine';
import Layout from '../components/layout';
import SEO from '../components/seo';

import LocationMap from '../components/LocationMap';

import withStore from '../withStore';
import './index.scss';

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
        services
      }
    }
  }
`;

const paddingSpace = '30px';
const heroVerticalMap = {
  extraSmall: { top: -100 },
  small: { top: -100 },
  medium: { top: -150 },
  large: { top: -200 },
  infinity: { top: -200 },
};
const Hero = ({ services, browser }) => (
  <ParallaxSection
    bgImageStyle={get(heroVerticalMap, browser.mediaType)}
    strength={500}
    backgroundImage={bg1}
    contentStyles={{ padding: `${paddingSpace} 0` }}
    bgImageAlt="Christian themed image of a church looking down the aisle"
  >
    <div className="">
      <Row className="text-light">
        <Col lg={7} md={8} className="d-flex align-items-center">
          <div>
            <h1 className="h4 text-nowra blackcolor" style={{ lineHeight: '2em' }}>
              <strong>
                Licensed Marriage and Family Therapist <br />
                Licensed Professional Counselor
              </strong>
            </h1>

            <p style={{ lineHeight: '2em' }}>
              I am currently providing mental health services to adults in
              the <a href="#suwanee-location">Suwanee</a> / <a href="#johnscreek-location">Johns Creek</a> area. I work with adults struggling with various
              mental health concerns or with areas where personal growth is
              needed. I also provide marital counseling services including
              helping couples or individuals deal with sexual concerns and
              sexual addiction.
            </p>
            <ul className="b-ul fa-ul" style={{ columns: get(browser, 'lessThan.medium', false) ? 1 : 2 }}>
              {
                map(services, (service, i) => (<Eli icon={faComments} iconClasses="font-brighter-blue" key={i} className="text-capitalize">{service}</Eli>))
              }
            </ul>

          </div>
        </Col>
        <Col lg={5} md={4} className="d-flex align-items-center">
          <div className="align-middle">
            <img className="img-fluid" src={corrieImg} alt="" style={{ display: 'table-cell' }} />
          </div>

        </Col>
      </Row>
    </div>
  </ParallaxSection>
);

const CredentialGridContentStyles = {
  learnDivWrapper: {
    position: 'relative',
    height: '100%',
    minHeight: 80,
  },
  learnAnchor: {
    position: 'absolute',
    top: 58,
  },
};
const CredentialGridContent = [
  {
    src: gottImg,
    alt: 'couple holding hands',
    content: (
      <div style={{ ...CredentialGridContentStyles.learnDivWrapper }}>
        <p><strong>Gottman Method Training (Levels 1 and 2)</strong></p>
        <a className="text-info" href="https://www.gottman.com/" style={{ ...CredentialGridContentStyles.learnAnchor }}>
          Learn More about Gottman
        </a>
      </div>
    ),
  },
  {
    src: bookImg,
    alt: 'book with rose',
    content: (
      <div style={{ ...CredentialGridContentStyles.learnDivWrapper }}>
        <p><strong>Prepare/Enrich Certified</strong></p>
        <a className="text-info" href="https://www.prepare-enrich.com" style={{ ...CredentialGridContentStyles.learnAnchor }}>
          Learn More about Prepare/Enrich
        </a>
      </div>
    ),
  },
  {
    src: ringImg,
    alt: 'cross with marriage rings',
    content: (
      <div>
        <p><strong>Licensed Marriage and Family Therapist</strong></p>
        <p><a className="text-info" href="http://verify.sos.ga.gov/verification/">License MFT001555</a></p>
        <p>
          GA Board of Professional Counselors, Social Workers,
          and Marriage &amp; Family Therapists
        </p>
      </div>

    ),
  },
  {
    src: anxImg,
    alt: 'anxiety scrabble letters',
    content: (
      <div>
        <p><strong>Licensed Professional Counselor</strong></p>
        <p><a className="text-info" href="http://verify.sos.ga.gov/verification/">License LPC009629</a></p>
        <p>
          GA Board of Professional Counselors, Social Workers,
          and Marriage &amp; Family Therapists
        </p>
      </div>

    ),
  },
];

const CredentialsGrid = ({ browser }) => {
  const gridSize = {
    xs: 12,
    sm: 6,
    lg: 3,
  };
  const cardStyles = {
    marginTop: get(browser, 'lessThan.medium', false) ? 10 : 30,
    marginBottom: get(browser, 'lessThan.medium', false) ? 10 : 30,
  };

  return (
    <Row>
      {
        map(CredentialGridContent, (data, i) => (
          <Col key={i} {...gridSize}>
            <Card style={cardStyles} className="border-0">
              <CardImg top width="100%" src={data.src} alt={data.alt} />
              <CardBody style={{ minHeight: get(browser, 'lessThan.large') ? 0 : 214 }}>
                {data.content}
              </CardBody>
            </Card>
          </Col>
        ))
      }
    </Row>
  );
};

const graduateGridVerticalMap = {
  extraSmall: { top: -100 },
  small: { top: -100 },
  medium: { top: -150 },
  large: { top: -200 },
  infinity: { top: -200 },
};
const GraduateGrid = ({ browser }) => (
  <ParallaxSection
    bgImageStyle={get(graduateGridVerticalMap, browser.mediaType)}
    blur={2}
    strength={-200}
    backgroundImage={mentalHealthImg}
    contentStyles={{ padding: '30px 0' }}
    bgStyle={{ top: '0px' }}
    bgImageAlt="Image of wooden block letters spelling mental health"
  >
    <Row className="text-light">
      <Col lg={7} md={8} className="d-flex align-items-center">
        <div>
          <div className="text-light clearfix">
            <h2 className="h1 font-weight-bold">Richmont Graduate University</h2>
            <p className="h3 mt-4">MAMFT, Marriage and Family Therapy</p>
            <p><em>Corrie earned a Master of Arts in Marriage and Family Therapy</em></p>
          </div>
        </div>
      </Col>
    </Row>
  </ParallaxSection>
);

const doResize = () => {
  const w = window;
  if (typeof w !== 'undefined') {
    const resizeEvent = w.document.createEvent('UIEvents');
    resizeEvent.initUIEvent('resize', true, false, w, 0);
    w.dispatchEvent(resizeEvent);
  }
};
class Home extends React.Component {
  componentDidMount() {
    // doResize();
    setTimeout(() => {
      doResize();
    }, 100);
  }

  render() {
    const {
      data: {
        site: {
          siteMetadata: {
            services, title,
          },
        },
      },
      browser,
    } = this.props;
    return (
      <Layout id="index-page">
        <SEO title={title} />
        <Hero {...{ services, browser }} />
        <GridSection>
          <ServicePoints {...{ browser }} />
        </GridSection>

        <GraduateGrid {...{ browser }} />
        <GridSection>
          <CredentialsGrid {...{ browser }} />
        </GridSection>
        <TimeLine {...{ browser }} />
        <ScrollableAnchor id="suwanee-location">
          <ScrollableAnchor id="johnscreek-location">
            <GridSection fluid style={{ height: 400 }}>
              <LocationMap />
            </GridSection>
          </ScrollableAnchor>
        </ScrollableAnchor>
      </Layout>
    );
  }
}

Hero.propTypes = {
  services: PropTypes.arrayOf(PropTypes.string).isRequired,
  browser: PropTypes.object.isRequired,
};

CredentialsGrid.propTypes = {
  browser: PropTypes.object.isRequired,
};

GraduateGrid.propTypes = {
  browser: PropTypes.object.isRequired,
};

Home.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        services: PropTypes.arrayOf(PropTypes.string).isRequired,
        title: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
  browser: PropTypes.object.isRequired,
};

Home.defaultProps = {
};

export default compose(
  withBreakpoints,
  withStore,
  connect(
    state => ({
      browser: state.browser,
    }),
  ),
)(Home);
