import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { Parallax } from 'react-parallax';
import { Container, Row, Col } from 'reactstrap';
import { merge } from 'lodash';

const defaultWrapperStyles = {};

const defaultContentStyles = {};

const ParallaxSection = ({
  backgroundImage, children,
  contentStyles, wrapperStyles, bgStyle,
  strength, blur, bgImageAlt, ...p
}) => {
  const mergedWrapperStyles = merge({}, defaultWrapperStyles, wrapperStyles);
  const mergedContentStyles = merge({}, defaultContentStyles, contentStyles);
  return (
    <div style={mergedWrapperStyles}>
      <Parallax
        bgImage={backgroundImage}
        bgImageAlt={bgImageAlt}
        {...{
          strength,
          bgStyle,
          blur,
        }}
        {...p}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <div style={mergedContentStyles}>
                {children}
              </div>
            </Col>
          </Row>
        </Container>
        <div className="clearfix" />
      </Parallax>
    </div>
  );
};

ParallaxSection.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  wrapperStyles: PropTypes.object,
  contentStyles: PropTypes.object,
  bgStyle: PropTypes.object,
  strength: PropTypes.number,
  blur: PropTypes.number,
  bgImageAlt: PropTypes.string.isRequired,
};

ParallaxSection.defaultProps = {
  wrapperStyles: {},
  contentStyles: {},
  strength: 200,
  bgStyle: {},
  blur: 2,
};

export default compose(
  pure,
)(ParallaxSection);
