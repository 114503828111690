import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons/faCheckCircle';

const Eli = ({
  children, icon, iconClasses, ...props
}) => (
  <li {...props} style={{ paddingTop: 10, paddingBottom: 10 }}>
    <FontAwesomeIcon icon={icon} listItem size="2x" style={{ top: 5 }} className={iconClasses} />
    <div style={{ paddingLeft: 25 }} className="align-middle">
      {children}
    </div>
  </li>
);

Eli.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.object,
  iconClasses: PropTypes.string,
};

Eli.defaultProps = {
  icon: faCheckCircle,
  iconClasses: '',
};

export default compose(
  pure,
)(Eli);
