import React from 'react';
import { compose } from 'redux';

import { Provider } from 'react-redux';
import configureStore from './store';


export default (WrappedComponent) => {
  const HOC = props => (
    <Provider store={configureStore}>
      <WrappedComponent {...props} />
    </Provider>
  );
  return compose(HOC);
};
