import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { graphql } from 'gatsby';
import { window } from 'browser-monads';

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
        services
      }
    }
  }
`;

const doResize = () => {
  const w = window;
  if (typeof w !== 'undefined') {
    const resizeEvent = w.document.createEvent('UIEvents');
    resizeEvent.initUIEvent('resize', true, false, w, 0);
    w.dispatchEvent(resizeEvent);
  }
};


const LocationMap = () => (
  <div style={{ height: 400 }}>
    <iframe
      title="Map to Corrie Brewton"
      width="100%"
      height="100%"
      frameBorder="0"
      style={{ border: 0 }}
      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyC5p_JgXZk0n7NdBMD3HFnUG-wslFeaANs&q=${encodeURI('Paraclete Counseling Center, Suwanee, GA 30024')}`}
      allowFullScreen
      onLoad={() => {
        doResize();
      }}
    />
  </div>
);

LocationMap.propTypes = {
};

LocationMap.defaultProps = {
};

export default compose(
)(LocationMap);
