import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import 'normalize.css/normalize.css';
import 'bootstrap/dist/css/bootstrap-reboot.css';
import 'bootstrap/dist/css/bootstrap-grid.css';
import '../theme/bs-theme.css';
import { pick } from 'lodash';
import { dom } from '@fortawesome/fontawesome-svg-core';

import Header from './header';
import Footer from './footer';

import './layout.scss';

const Layout = ({ children, id }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            credentials
            fullName
            city
            email
            state
            phone
            phoneExt
            postalCode
          }
        }
      }
    `}
    render={(data) => {
      const headerProps = pick(data.site.siteMetadata, [
        'credentials',
        'fullName',
        'city',
        'email',
        'state',
        'phone',
        'phoneExt',
        'postalCode',
      ]);
      const footerProps = pick(data.site.siteMetadata, [
        'credentials',
        'fullName',
      ]);

      return (
        <div id={id}>
          <style>
            {dom.css()}
          </style>
          <Header {...headerProps} />
          <main id="site-main">{children}</main>
          <Footer {...footerProps} />
        </div>
      );
    }
  }
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};

export default Layout;
