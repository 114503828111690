import PropTypes from 'prop-types';
import React from 'react';
import {
  Container, Row, Col, Navbar, NavbarBrand, NavLink,
} from 'reactstrap';
import Headroom from 'react-headroom';

const Header = ({
  fullName,
  credentials,
  city,
  state,
  postalCode,
  phone,
  phoneExt,
  email,
}) => (
  <Headroom>
    <header id="site-header">
      <Navbar color="primary" dark>
        <Container>
          <Row style={{ width: '100%' }}>
            <Col sm={6}>
              <NavbarBrand tag="span">{`${fullName}, ${credentials}`}</NavbarBrand>
            </Col>
            <Col sm={6}>
              <NavbarBrand tag="span" className="float-sm-right">{`${city}, ${state} ${postalCode}`}</NavbarBrand>
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <Col sm={8}>
              <NavLink className="text-white" href={`tel:${phone}`}>Phone: {phone}, ext. {phoneExt}</NavLink>
              <NavLink href={`mailto:${email}`} className="text-white">
                {email}
                <span className="text-white"> (Please do not email confidential information)</span>
              </NavLink>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </header>
  </Headroom>
);

Header.propTypes = {
  fullName: PropTypes.string.isRequired,
  credentials: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  postalCode: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  phoneExt: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default Header;
