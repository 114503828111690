import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import {
  Row, Col,
} from 'reactstrap';
import { map } from 'lodash';
import Eli from './Eli';

const ServicePointsContent = [
  {
    leader: 'Cognitive Behavioral Therapy (CBT)',
    content: (
      <span>
        I often use CBT techniques as they are an evidenced
        based treatment option which is focused on challenging
        negative thought processes and changing unhealthy behaviors.
      </span>
    ),
  },
  {
    leader: 'Christian Beliefs',
    content: (
      <span>
        Christian views may be incorporated at whatever level
        preferred by the client, or not at all.
      </span>
    ),
  },
  {
    leader: 'Marriage and Family Focus',
    content: (
      <span>
        experienced in helping couples address various concerns
        regarding their marriage. Topics such as communication,
        conflict resolution, establishing healthy boundaries,
        improving healthy intimacy, and recovering from trust
        breaks are addressed.
      </span>
    ),
  },
  {
    leader: 'Gottman Method',
    content: (
      <span>
        focused training specifically addresses interventions
        and the management of conflict. Scientifically proven
        tools and techniques are practiced to aid in the building
        of relationships and intimacy.
      </span>
    ),
  },
  {
    leader: 'Prepare/Enrich',
    content: (
      <span>
        specialized training in premarital and marriage assessments.
        This certification recognizes proficiency in understanding
        the dynamics of couples and identifying relationship
        strengths. Understanding the assessment allows couples
        to develop skills needed to grow their relationship.
      </span>
    ),
  },
  {
    leader: 'Sexual Concerns',
    content: (
      <span>
        received special training to address various sexual
        concerns that affect the marriage including sexual
        dysfunction, sexual pain, sexual addiction, and
        pornography addiction.
      </span>
    ),
  },
];

const ServicePoints = ({ paddingSpace }) => (
  <Row className="justify-content-center" style={{ padding: `${paddingSpace} 0` }}>
    <Col md={8}>
      <ul className="b-ul fa-ul">
        {
          map(ServicePointsContent, (data, i) => (
            <Eli key={i} iconClasses="font-brighter-blue">
              <strong>{data.leader}</strong> - {data.content}
            </Eli>
          ))
        }
      </ul>
    </Col>
    {

    }
  </Row>
);

ServicePoints.propTypes = {
  paddingSpace: PropTypes.string,
};

ServicePoints.defaultProps = {
  paddingSpace: '30px',
};

export default compose(
  pure,
)(ServicePoints);
